<template>
	<span>
		<span @click="onShowModal">
			<slot>
				<a-button :disabled="disabled" type="primary">选择{{ ['兑换券', '', '卖品券'][couponType - 1]  }}批次</a-button>
			</slot>
		</span>
		<a-modal v-model:visible="visible" :title="['兑换券批次', '', '卖品券批次'][couponType - 1]" width="850px" @cancel="onCancel" @ok="onOK">
			<a-spin :spinning="loading">
				<a-form ref="form" :model="formState" name="form" @finish="onSearch">
					<a-row>
						<a-form-item label="批次号" name="batchNo" class="ui-form__item">
							<a-input v-model:value="formState.batchNo" placeholder="请输入批次号"></a-input>
						</a-form-item>

						<a-form-item label="批次号名称" name="batchName" class="ui-form__item">
							<a-input v-model:value="formState.batchName" placeholder="请输入批次号名称"></a-input>
						</a-form-item>

						<a-form-item v-if="couponType === 1" label="是否通兑" name="isHoliday" class="ui-form__item">
							<a-select v-model:value="formState.isHoliday" placeholder="请选择是否通兑" style="width: 160px;">
								<a-select-option :value="1">通兑</a-select-option>
								<a-select-option :value="0">非通兑</a-select-option>
							</a-select>
						</a-form-item>

						<a-form-item label="有效期开始时间" class="ui-form__item">
							<a-range-picker v-model:value="time"></a-range-picker>
						</a-form-item>

						<a-form-item label="有效期结束时间" class="ui-form__item">
							<a-range-picker v-model:value="time1"></a-range-picker>
						</a-form-item>
					</a-row>

					<a-row>
						<a-col :span="18">

						</a-col>
						<a-col :span="6" style="text-align: right;">
							<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
							<a-button @click="reset">重置</a-button>
						</a-col>
					</a-row>
				</a-form>

				<div style="margin-top: 20px;">
					<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll, }" :scroll="{ x: couponType === 1 ? 1600 : 1000 }">
						<template #bodyCell="{ column, record }">
							<template v-if="column.key === 'createdTime'">
								{{ transDateTime(record.createTime) }}
							</template>

							<template v-if="column.key === 'time'">
								<div v-if="record.validPeriodType == 7">
									领取后{{ record.period }}天内有效
								</div>
								<div v-else>
									{{ transDateTime(record.receiveStartTime, 1) }} - {{ transDateTime(record.receiveEndTime, 1) }}
								</div>
							</template>

							<template v-if="column.key === 'redeemedCount'">
								{{ record.importCount - record.redeemedCount }}
							</template>

							<template v-if="column.key === 'isHoliday'">
								<div v-if="record.isHoliday">通兑</div>
								<div v-else>非通兑</div>
							</template>
						</template>
					</a-table>
				</div>
			</a-spin>
		</a-modal>
	</span>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getImportRecord } from '@/service/modules/coupon.js';
	import { batchReplaceCouponPolicy } from '@/service/modules/rules.js';
	export default {
		components: {Icon },
		model: {
			prop: 'value'
		},
		props: {
			id: {
				type: Number,
				default: 0
			},
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			disabled: {
				type: Boolean,
				default: false
			},
			type: {
				type: String,
				default: 'radio'
			},
			couponType: {
				type:Number,
				default: 1
			},
			selectItem: {
				type: Array,
				default: () => {
					return [];
				}
			},
			from: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				loading: false,
				visible: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
				},
				time: [],
				time1: [],
				cinemaList:[],
				searchData: {},
				showModal: false,
				modelRef: {
					time: []
				},
				list: [],
				columns:[],
				exchangeColumns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				},{
					title: '批次号',
					dataIndex: 'batchNo'
				}, {
					title: '批次号名称',
					dataIndex: 'name'
				}, {
					title: '有效期',
					key: 'time'
				}, {
					title: '是否通兑',
					key: 'isHoliday',
					width: 150
				}, {
					title: '补差金额（元）',
					dataIndex: 'diffPrice',
					width: 150
				}, {
					title: '兑换券数量',
					dataIndex: 'importCount',
					width: 150
				}, {
					title: '已绑定数量',
					dataIndex: 'bindCount',
					width: 150
				}, {
					title: '未兑换数量',
					key: 'redeemedCount',
					width: 130
				}],
				snackColumns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				},{
					title: '批次号',
					dataIndex: 'batchNo'
				},{
					title: '批次号名称',
					dataIndex: 'name'
				},{
					title: '有效期',
					key: 'time'
				}, {
					title: '卖品券数量',
					dataIndex: 'importCount',
					width: 150
				}, {
					title: '已绑定数量',
					dataIndex: 'bindCount',
					width: 150
				}, {
					title: '未兑换数量',
					key: 'redeemedCount',
					width: 130
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				selectedRowKeys: [],
				selectedListData: [],
			}
		},
		created() {
			//this.onSearch();
		},
		methods: {
			onShowModal() {
				if (this.disabled) return;
				if (!this.id) return this.$message.warn('请选择所属影院');
				if (this.couponType === 1) {
					this.columns = this.exchangeColumns;
				} else {
					this.columns = this.snackColumns;
				}
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
				this.selectedListData = JSON.parse(JSON.stringify(this.selectItem));
				this.pagination.pageSize = 10;
				this.visible = true;
				this.$nextTick(()=> {
					this.reset();
				})
			},
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.isGrant = this.from === 'extension' ? 0 : 1;
				if (this.time && this.time.length) {
					this.searchData.validStartTime = this.time[0].startOf('day').unix();
					this.searchData.validEndTime = this.time[1].endOf('day').unix();
				}
				if (this.time1 && this.time1.length) {
					this.searchData.failureStartTime = this.time1[0].startOf('day').unix();
					this.searchData.failureEndTime = this.time1[1].endOf('day').unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.time = [];
				this.time1 = [];
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getImportRecord({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						couponType: this.couponType,
						cinemaId: this.id,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						this.selectedListData = this.selectedListData.filter(item => {
							return item.id !== record.id;
						});
					}
				} else {
					if (selected) {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.id];
					} else {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onCancel() {
			    this.visible = false;
			    this.$emit('cancel');
			    this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
			    this.selectedListData = JSON.parse(JSON.stringify(this.selectItem));
			},
			onOK() {
				this.$emit('change', this.selectedListData);
				this.$emit('update:value', this.selectedRowKeys);
				this.visible = false;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
